import { Injectable, inject, signal } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  finalize,
  firstValueFrom,
  map,
  tap,
} from 'rxjs';
import { ApiService } from '../../api/api.service';
import {
  IPaymentResponseData,
  IAddressDetails,
  IBankAccountDetails,
  IPaymentConfigDetails,
  IPaymentDetails,
  IPaymentConfigAdvancedDetails,
} from '../../../models/common-models';

@Injectable({
  providedIn: 'root',
})
export class PaymentDataService {
  private apiService = inject(ApiService);

  widgetId: string = '';
  appMode: 'widget' | 'link' | 'test' = 'link';
  defaultAmount?: number;
  advancedLink?: string;
  private paymentDetails = new BehaviorSubject<IPaymentDetails>({
    amount: 0,
    email: '',
    paymentMethod: false,
    paymentMode: 'ONCE',
    isPaymentDetailsDataValid: false,
  });

  private paymentConfigDetails = new BehaviorSubject<
    IPaymentConfigDetails | undefined
  >(undefined);

  private paymentConfigAdvancedDetails = new BehaviorSubject<
    IPaymentConfigAdvancedDetails | undefined
  >(undefined);

  isUserFirstTimer = signal(false);

  paypalScriptLoading = new BehaviorSubject<boolean>(false);

  paymentResponseData: IPaymentResponseData | undefined;
  bankAccountDetails: IBankAccountDetails | undefined;
  addressDetails: IAddressDetails | undefined;
  yodleeWindowRef = new BehaviorSubject<Window | null>(null);

  initialLoading = signal(true);

  getPaymentConfigDetails(): Observable<IPaymentConfigDetails | undefined> {
    return this.paymentConfigDetails.asObservable();
  }

  getPaymentConfigAdvancedDetails() {
    return this.paymentConfigAdvancedDetails.asObservable();
  }

  getPymntConfAdvDtlsCurrentVal() {
    return this.paymentConfigAdvancedDetails.value;
  }

  getPaymentConfigDetailsCurrentValue(): IPaymentConfigDetails | undefined {
    return this.paymentConfigDetails.getValue();
  }

  getPaymentDetailsCurrentValue(): IPaymentDetails {
    return this.paymentDetails.getValue();
  }

  getPaymentDetails(): Observable<IPaymentDetails> {
    return this.paymentDetails;
  }

  async setPaymentAmount(amount: number) {
    const paymentDetials = await firstValueFrom(
      this.paymentDetails.pipe(map((val) => ({ ...val, amount }))),
    );
    this.paymentDetails.next(paymentDetials);
  }

  setPaymentDetails(incomingPaymentDetails: IPaymentDetails) {
    this.paymentDetails.next(incomingPaymentDetails);
  }

  setAddressDetails(details: IAddressDetails) {
    this.addressDetails = details;
  }
  getAddressDetails(): IAddressDetails | undefined {
    return this.addressDetails;
  }

  setBankAccountDetails(details: IBankAccountDetails) {
    this.bankAccountDetails = details;
  }
  getBankAccountDetails(): IBankAccountDetails | undefined {
    return this.bankAccountDetails;
  }

  setWidgetIDAndRequestPaymentMethodsDetails(
    widgetId: string,
    amount?: string,
    advancedLink?: string,
  ) {
    const amt = Number(amount ?? '') || undefined;
    if (this.widgetId != widgetId) {
      this.widgetId = widgetId;
      this.defaultAmount = amt;
      this.advancedLink = advancedLink;
      return this.requestPaymentMethodsDetails();
    }
    return null;
  }

  private requestPaymentMethodsDetails() {
    return this.apiService
      .getPaymentConfigDetails(this.widgetId, this.advancedLink)
      .pipe(
        tap((res) => {
          if (res?.data?.form_details) {
            this.paymentConfigDetails.next(res.data.form_details);
            this.paymentConfigAdvancedDetails.next(res.data.advanced);
            this.isUserFirstTimer.update(() =>
              Boolean(
                res.data.form_details.is_payment_link_transaction_first_time,
              ),
            );
            if (res.data.advanced) {
              this.defaultAmount =
                Number(res.data.advanced.amount) || this.defaultAmount;
            }
          }
        }),
        finalize(() => {
          this.initialLoading.update(() => false);
        }),
      );
  }

  openYodlee(url: string) {
    this.yodleeWindowRef.next(
      window.open(url, '_blank', 'width=500,height=700,top=50%'),
    );

    const intervalTime = setInterval(() => {
      if (this.yodleeWindowRef.value?.closed) {
        this.yodleeWindowRef.next(null);
        clearInterval(intervalTime);
      }
    }, 500);
  }

  focusYodleeWindow() {
    this.yodleeWindowRef.value?.focus();
  }

  closeYodleeWindow() {
    this.yodleeWindowRef.value?.close();
  }
}
