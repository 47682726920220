import { Injectable, signal } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { IRecieptPrintDetails } from '../../../models/common-models';
import { environment } from 'projects/payment-link/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  backClick = new Subject<void>();

  invlidId = new Subject<boolean>();
  private _loading = new BehaviorSubject<boolean>(false);

  public get loading() {
    return this._loading;
  }

  private _printReciept = new Subject<IRecieptPrintDetails>();

  public get printReciept() {
    return this._printReciept;
  }

  public set printReciept(value) {
    this._printReciept = value;
  }

  getBackClick() {
    return this.backClick.asObservable();
  }

  inHome = signal(false);
  rootUrl = signal(environment.rootUrl);

  onBackClick() {
    this.backClick.next();
  }

  closeWidgetMessage() {
    window.parent.postMessage('close-ocw-widget', '*');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static getValidationErrorMessage(
    validatorName: string,
    validatorValue?: any,
    labelName?: string,
  ): any {
    const data = {
      required: labelName || 'This field is required',
      invalidPassword: `Invalid password. Password must be at least 6 characters long, and contain a number.`,
      invalidEmailAddress: `Invalid email address`,
      invalidPhoneNumber: `Invalid phone number.`,
      invalidRoutingNumber: `Invalid Routing Number`,
      invalidZip: `Enter valid zip.`,
      minlength: `The field must contain atleast ${validatorValue.requiredLength} characters.`,
      maxlength: `The field can't contain more than ${validatorValue.requiredLength} characters.`,
      min: labelName || `Minimum value must be ${validatorValue.min}`,
      hasWhitespace: labelName || 'This field has whitespace',
      accountNoMismatch: 'Incorrect account number',
      pattern: '',
      poBox: 'PO Box address not allowed',
      email: 'Invalid email address',
      minimumDateInvalid: 'Select a future date',
    };

    return data[validatorName as keyof typeof data];
  }

  static routingNumberValidator(
    control: AbstractControl,
  ): ValidationErrors | null {
    // Run through each digit and calculate the total.
    let n = 0;
    const rt_number = control.value;

    if (control.value == null || control.value == '') {
      return null;
    }
    for (let i = 0; i < rt_number.length; i += 3) {
      n +=
        parseInt(rt_number.charAt(i), 10) * 3 +
        parseInt(rt_number.charAt(i + 1), 10) * 7 +
        parseInt(rt_number.charAt(i + 2), 10);
    }
    // If the resulting sum is an even multiple of ten (but not zero),
    // the aba routing number is good.

    if (n != 0 && n % 10 == 0) {
      return null;
    }
    return { invalidRoutingNumber: false };
  }

  static dateMinimumValidator(
    control: AbstractControl,
  ): ValidationErrors | null {
    if (control.value == null || control.value == '') {
      return null;
    }
    try {
      const controlDate = new Date(control.value);
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      if (controlDate > now) {
        return null;
      } else {
        return { minimumDateInvalid: false };
      }
    } catch (err) {
      return { minimumDateInvalid: false };
    }
  }

  // PHONE NUMBER

  static phoneNumberValidator(
    control: AbstractControl,
  ): ValidationErrors | null {
    const masknum = control.value;
    const num = String(control.value);

    const num1 = num.substring(0, 3);
    const num2 = num.substring(3, 6);
    const num3 = num.substring(6, 10);

    const number = '(' + num1 + ') ' + num2 + '-' + num3;
    if (control.value == null || control.value == '') {
      return null;
    }
    if (!number) {
      return { invalidPhoneNumber: true };
    }

    if (
      number.match(/(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/g) ||
      masknum.match(/(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/g)
    ) {
      return null;
    }
    return { invalidPhoneNumber: true };
  }

  // EMAIL
  static emailValidator(control: AbstractControl): any {
    const email = String(control.value);

    if (control.value == null || control.value == '') {
      return true;
    }
    // if (!control.value) { return { invalidEmailAddress: true }; }

    if (email.match(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,15})?$/)) {
      return false;
    }

    return { invalidEmailAddress: true };
  }
}
